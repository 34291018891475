// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";


.noty_theme__mint.noty_bar {
    margin: 0 0;
    margin-top: -7px;
}


html {
    scroll-behavior: smooth;
}

html, body {
    background-color: $body-bg;
    color: $body-color;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
    height: 100vh;
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 720px) {
    html, body {
        @include font-size(14px);
    }
}

.full-width {
    width: 100vw;
}

.full-height {
    height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.title {
    font-family: 'Open Sans', sans-serif;
}

.title > a {
    text-decoration: none;
    text-transform: uppercase;
}

.links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.m-b-md {
    margin-bottom: 30px;
}

@media (hover:none) {
    /* start mobile styles */
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="tel"], input[type="url"]{ font-size: 16px !important;}

    .select2-container--bootstrap .select2-search--dropdown .select2-search__field {
        font-size: 16px !important;
    }
    /* end mobile styles */
}

#main-header {
    position: relative;
    width: 100%;
    z-index: 999;
    background: #fff;
}
@media (min-width: 300px) {
    body.sticky main {
        margin-top: 115px !important;
    }

    body.sticky #main-header {
        position: fixed;
        top: -115px;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.095) !important;
        transition: all .4s ease-in-out;
    }

    #logo {

    }
}

@media (min-width: 768px) {
    body.sticky main {
        margin-top: 115px !important;
    }

    body.sticky #main-header {
        position: fixed;
        top: -115px;
        transition: all .4s ease-in-out;
    }
}

.form-group.row {
    margin-bottom: 1rem;
}
@media (min-width: 768px) {
    .col-form-label.text-md-right {
        text-align: right!important;
    }
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    color: #fff !important;
}

.btn-outline-secondary:hover, .btn-outline-secondary:active, .btn-outline-secondary:visited {
    color: #fff !important;
}

.btn-outline-secondary:hover img, .btn-outline-secondary:active img, .btn-outline-secondary:visited img {
    filter: brightness(0) invert(1);
}


b,
strong {
    font-weight: bold;
}

footer .nav-link {
    color: #959595;
    text-transform: uppercase;
}
footer .nav-link.active {
    color: rgb(123, 123, 123);
}


[x-cloak] {
    display: none !important;
}

ul.pagination {
    justify-content: center;
}

a h5 {
    text-decoration: none;
}

.text-any-light-blue {
    color: #25a9df;
}
.text-any-blue {
    color: #1b75ba;
}
.text-any-dark-blue {
    color: #282a3e;
}

.bg-any-green {
    background: #f1f7f3;
}
.bg-any-yellow {
    background: #fffef0;
}

.select2-container--default .select2-selection--single {
    border: 1px solid #ced4da !important;
    padding: 2px 10px !important;
    line-height: 1.6 !important;
    height: 37px !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    font-size: 16px !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 2px 0px !important;
    font-size: 16px !important;
    padding-right: 20px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 5px !important;
    right: 9px !important;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #212529 !important;
}


.form-control {
    font-size: 16px !important;
}


